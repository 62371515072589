<template>
  <div class="logistics">
    <MapHeatmapBlock style="height: 100vh; width: 100%" />
  </div>
</template>

<script>
export default {
  components: {
    MapHeatmapBlock: () => ({
      component: import("@/components/map/map-heatmap.vue"),
    }),
  },
  data() {
    return {
      percent: [],
      series: [144, 55, 13],
      chartOptions: {
        dataLabels: {
          enabled: false,
        },
        fill: {
          colors: ["#ed8230", "#83B1F4", "#E0E9FA"],
        },
      },
    };
  },
  mounted() {
    const sell = this.series.reduce((sum, item) => {
      sum += item;
      return sum;
    });
    this.series.forEach((key, i) => {
      this.percent[i] = ((key * 100) / sell).toFixed();
    });
  },
};
</script>

<style scoped>
.logistics {
  margin-top: 20px;
}
.logistics__block {
  height: 322px;
  box-sizing: border-box;
  width: 48%;
  min-height: auto;
}
.logistics__block-head {
  padding-right: 3px;
  margin-bottom: 5px;
}
.logistics__box {
  align-items: flex-start;
}
.logistics__items {
  /* width: 40%; */
  padding: 15px 0 0 20px;
}
.logistics__item {
  align-items: flex-start;
  height: 35px;
}
.logistics__item:not(:last-child) {
  margin-bottom: 48px;
}
.logistics__info {
  margin-left: 10px;
}
.logistics__number {
  margin-bottom: 5px;
  font-family: "Product Sans", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  line-height: 18px;
  color: #000000;
}
.logistics__text {
  font-family: "Product Sans", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: 12px;
  color: #000000;
}
.logistics__percent {
  font-family: "Product Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 12px;
  color: #acacac;
  margin-left: 10px;
}
.checkbox-block {
  width: 14px;
}
.checkbox-label {
  width: 14px;
  height: 14px;
  border-radius: 4px;
  background: var(--secondary-color);
}
.checkbox-label__two {
  background: #83b1f4;
}
.checkbox-label__three {
  background: #e0e9fa;
}
#chart {
  position: relative;
}
.total {
  position: absolute;
  top: 11%;
  left: 50%;
  transform: translate(-50%, 50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.total__title {
  font-family: "Product Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 37px;
  color: #000000;
  margin: 8px 0;
}
.total__text {
  font-family: "Product Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: #000000;
}
</style>
